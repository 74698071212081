var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"addItemToWriteOff",attrs:{"title":_vm.$t('material.adding_ingredient'),"size":"lg"},on:{"ok":_vm.validate},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.hide}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.addNewItem}},[(!_vm.editing)?_c('span',[_vm._v(_vm._s(_vm.$t('general.add')))]):_c('span',[_vm._v(_vm._s(_vm.$t('general.edit')))])])]},proxy:true}])},[_c('validation-observer',{ref:"validationAddItem"},[_c('div',[_c('b-form-group',{attrs:{"label":_vm.$t('general.ingredient')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.ingredient'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"appendToBody":"","options":_vm.showIngredients,"label":"name","clearable":false},on:{"option:selected":_vm.changeDefault},model:{value:(_vm.selectedIngredient),callback:function ($$v) {_vm.selectedIngredient=$$v},expression:"selectedIngredient"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.quantity')}},[_c('validation-provider',{attrs:{"name":_vm.$t('general.ingredient'),"rules":"required|min_value:0|max_value:1000000|decimalCustom:0|mustBePositive:true"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient,"lazy":"","max":"1000000"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=_vm._n($$v)},expression:"quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.packaging')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.packaging'),"rules":_vm.getQuantityRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","disabled":!_vm.selectedIngredient},model:{value:(_vm.packaging),callback:function ($$v) {_vm.packaging=_vm._n($$v)},expression:"packaging"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 col-md-4"},[_c('b-form-group',{attrs:{"label":_vm.$t('form_data.unit')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form_data.unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"unit-select",attrs:{"disabled":!_vm.selectedIngredient,"appendToBody":"","label":"name","options":_vm.types,"clearable":false},model:{value:(_vm.addItem.packaging_unit),callback:function ($$v) {_vm.$set(_vm.addItem, "packaging_unit", $$v)},expression:"addItem.packaging_unit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }