<template>
    <custom-loader :show="showLoader">

        <add-warehouses-move-supply ref="editWarehouseMoveSupply" @refresh="load"/>
    </custom-loader>
</template>

<script>
    import AddWarehousesMoveSupply from '@/views/MoveSupplies/AddWarehousesMoveSupply.vue'
    import * as Sentry from '@sentry/vue'
    import CustomLoader from '@/components/CustomLoader.vue'

    export default {
        components: {
            CustomLoader,
            AddWarehousesMoveSupply
        },
        data() {
            return {
                moveSupply: null,
                moveSupplyTemporary: null,
                moveSupplyId: '',
                editing: false,
                showLoader:false
            }
        },
        methods:{
            async loadDataEditing() {
                try {
                    this.$refs.editWarehouseMoveSupply.showLoader = true
                    const res1 = await this.$http.get(`/api/client/v1/warehouses/move_supply/${this.moveSupplyId}/`)
                    const res2 = await this.$http.get(`/api/client/v1/move_supplies_temporary/${this.moveSupplyId}/`)

                    const responses = await Promise.all([res1, res2])
                    this.moveSupply = responses[0].data.move_supply ?? {}
                    this.editing = responses[0].data.exists ?? false
                    this.moveSupplyTemporary = responses[1].data ?? {}
                    
                    this.moveSupply.timestamp = this.dayjs(this.moveSupply.timestamp).toISOString()
                    this.moveSupplyTemporary.timestamp = this.dayjs(this.moveSupplyTemporary.timestamp).toISOString()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.$refs.editWarehouseMoveSupply.showLoader = false
                }
            },
            async load() {
                this.moveSupplyId = this.$route.params.id
                await this.loadDataEditing()
                if (this.moveSupplyTemporary.id === '000000000000000000000000') {
                    this.moveSupplyTemporary = this.moveSupply
                }
                await this.$refs.editWarehouseMoveSupply.openEditing(this.moveSupplyTemporary, this.moveSupply, this.editing)
            }
        },

        async mounted() {
            await this.load()
        }
    }
</script>